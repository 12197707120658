<template>
  <div v-if="error" class="box error">
    <h2 class="mb-3">
      {{ $t('error') }}
    </h2>
    <p>{{ $t('unexpected-error-info-message') }}</p>
  </div>
  <div v-else class="content-wrapper">
    <div class="panel">
      <p class="panel-heading">
        {{ $t('advanced-search') }}
      </p>
      <div class="panel-block">
        <div class="flex">
          <BInput
            :value="searchString"
            :placeholder="$t('search')"
            class="search-projects"
            type="search"
            icon="search"
            @input="debounceSearchString"
          />
        </div>

        <!-- Tags hidden until scoped access per user per project -->
        <!-- <BCollapse open>
          <div class="mt-4 p-4 radius-12 bg-gray-1">
            <div class="columns">
              <div class="column">
                <div class="mb-2 size-12 uppercase">
                  {{ $t('tags') }}
                </div>
                <CytomineMultiselect
                  v-model="selectedTags"
                  :options="availableTags"
                  multiple
                  :all-placeholder="$t('all')"
                  label="name"
                  track-by="id"
                />
              </div>
              <div class="column p-0" />
              <div class="column p-0" />
            </div>
          </div>
        </BCollapse> -->
      </div>
      <p class="panel-tabs">
        <IdxBtn
          :class="[
            'border-black p-2',
            { 'border-b': activeTab === 'projects' },
            { 'color-primary': activeTab !== 'projects' },
          ]"
          plain
          @click="activeTab = 'projects'"
        >
          {{ $t('studies') }} ({{ projects.totalNbItems }})
        </IdxBtn>
        <IdxBtn
          :class="[
            'border-black p-2',
            { 'border-b': activeTab === 'images' },
            { 'color-primary': activeTab !== 'images' },
          ]"
          plain
          @click="activeTab = 'images'"
        >
          {{ $t('images') }} ({{ images.totalNbItems }})
        </IdxBtn>
      </p>
      <div class="panel-block">
        <BLoading :is-full-page="false" :active="loading" />

        <div v-show="activeTab === 'projects'" :key="'projects'">
          <CytomineTable
            :collection="projectCollection"
            :current-page.sync="currentPage"
            :per-page.sync="perPage"
            :opened-detailed.sync="openedDetails"
            :sort.sync="sortField"
            :order.sync="sortOrder"
            :data.sync="projects"
            :revision="revision"
          >
            <template #default>
              <BTableColumn
                v-slot="props"
                field="currentUserRole"
                label=""
                centered
                width="1"
                sortable
              >
                <IconProjectMemberRole
                  :is-manager="props.row.currentUserRoles.admin"
                  :is-representative="props.row.currentUserRoles.representative"
                />
              </BTableColumn>

              <BTableColumn
                v-slot="props"
                :label="$t('name')"
                field="name"
                sortable
                width="250"
              >
                <RouterLink :to="`/project/${props.row.id}`">
                  {{ props.row.name }}
                </RouterLink>
              </BTableColumn>

              <BTableColumn
                v-slot="props"
                :label="$t('members')"
                field="membersCount"
                centered
                sortable
                width="150"
              >
                {{ props.row.membersCount }}
              </BTableColumn>

              <BTableColumn
                v-slot="props"
                :label="$t('images')"
                field="numberOfImages"
                centered
                sortable
                width="150"
              >
                <RouterLink :to="`/project/${props.row.id}/images`">
                  {{ props.row.numberOfImages }}
                </RouterLink>
              </BTableColumn>

              <BTableColumn
                v-slot="props"
                :label="$t('user-annotations')"
                field="numberOfAnnotations"
                centered
                sortable
                width="150"
              >
                <RouterLink
                  :to="`/project/${props.row.id}/annotations?type=user`"
                >
                  {{ props.row.numberOfAnnotations }}
                </RouterLink>
              </BTableColumn>

              <BTableColumn
                v-slot="props"
                :label="$t('analysis-annotations')"
                field="numberOfJobAnnotations"
                centered
                sortable
                width="150"
              >
                <RouterLink
                  :to="`/project/${props.row.id}/annotations?type=algo`"
                >
                  {{ props.row.numberOfJobAnnotations }}
                </RouterLink>
              </BTableColumn>

              <BTableColumn
                v-slot="props"
                :label="$t('reviewed-annotations')"
                field="numberOfReviewedAnnotations"
                centered
                sortable
                width="150"
              >
                <RouterLink
                  :to="`/project/${props.row.id}/annotations?type=reviewed`"
                >
                  {{ props.row.numberOfReviewedAnnotations }}
                </RouterLink>
              </BTableColumn>

              <BTableColumn
                v-slot="props"
                :label="$t('last-activity')"
                field="lastActivity"
                centered
                sortable
                width="180"
              >
                {{ Number(props.row.lastActivity) | date('ll') }}
              </BTableColumn>

              <BTableColumn v-slot="props" label=" " centered width="150">
                <RouterLink
                  :to="`/project/${props.row.id}`"
                  class="button is-small is-link"
                >
                  {{ $t('open') }}
                </RouterLink>
              </BTableColumn>
            </template>

            <template #detail="{ row: project }">
              <ProjectDetails
                :project="project"
                :excluded-properties="excludedProperties"
                @update="updateProject()"
                @delete="deleteProject(project)"
              />
            </template>

            <template #empty>
              <div class="content has-text-grey text-center">
                <p>{{ $t('study-none') }}</p>
              </div>
            </template>
          </CytomineTable>

          <div class="radius-12 p-4 bg-gray-1">
            <h2 class="mb-3">
              {{ $t('legend') }}
            </h2>
            <p class="mb-2">
              <IconProjectMemberRole /> : {{ $t('contributor-icon-label') }}
            </p>
            <p class="mb-2">
              <IconProjectMemberRole :is-manager="true" /> :
              {{ $t('manager-icon-label') }}
            </p>
            <p>
              <IconProjectMemberRole
                :is-manager="true"
                :is-representative="true"
              />
              : {{ $t('representative-icon-label') }}
            </p>
          </div>
        </div>

        <div v-show="activeTab === 'images'" :key="'images'">
          <CytomineTable
            :collection="imageCollection"
            :current-page.sync="currentPage"
            :per-page.sync="perPage"
            :opened-detailed.sync="openedDetails"
            :sort.sync="sortField"
            :order.sync="sortOrder"
            :data.sync="images"
            :revision="revision"
          >
            <template #default>
              <BTableColumn v-slot="props" :label="$t('overview')" width="100">
                <RouterLink
                  :to="`/project/${props.row.project}/image/${props.row.id}`"
                >
                  <img
                    :src="props.row.thumb"
                    class="image-overview"
                    alt="image overview"
                  />
                </RouterLink>
              </BTableColumn>

              <BTableColumn
                v-slot="props"
                :label="$t('name')"
                field="instanceFilename"
                sortable
                width="400"
              >
                <RouterLink
                  :to="`/project/${props.row.project}/image/${props.row.id}`"
                >
                  <ImageName :image="props.row" show-both-names />
                </RouterLink>
              </BTableColumn>

              <BTableColumn
                v-slot="props"
                :field="'projectName'"
                :label="$t('study')"
                width="200"
              >
                <RouterLink :to="`/project/${props.row.project}`">
                  {{ props.row.projectName }}
                </RouterLink>
              </BTableColumn>

              <BTableColumn
                v-slot="props"
                :label="$t('magnification')"
                field="magnification"
                centered
                sortable
                width="100"
              >
                {{ props.row.magnification || $t('unknown') }}
              </BTableColumn>

              <BTableColumn
                v-slot="props"
                :label="$t('user-annotations')"
                field="numberOfAnnotations"
                centered
                sortable
                width="100"
              >
                <RouterLink
                  :to="`/project/${props.row.project}/annotations?image=${props.row.id}&type=user`"
                >
                  {{ props.row.numberOfAnnotations }}
                </RouterLink>
              </BTableColumn>

              <BTableColumn
                v-slot="props"
                :label="$t('analysis-annotations')"
                field="numberOfJobAnnotations"
                centered
                sortable
                width="100"
              >
                <RouterLink
                  :to="`/project/${props.row.project}/annotations?image=${props.row.id}&type=algo`"
                >
                  {{ props.row.numberOfJobAnnotations }}
                </RouterLink>
              </BTableColumn>

              <BTableColumn
                v-slot="props"
                :label="$t('reviewed-annotations')"
                field="numberOfReviewedAnnotations"
                centered
                sortable
                width="100"
              >
                <RouterLink
                  :to="`/project/${props.row.project}/annotations?image=${props.row.id}&type=reviewed`"
                >
                  {{ props.row.numberOfReviewedAnnotations }}
                </RouterLink>
              </BTableColumn>

              <BTableColumn v-slot="props" label=" " centered width="150">
                <RouterLink
                  :to="`/project/${props.row.project}/image/${props.row.id}`"
                  class="button is-small is-link"
                >
                  {{ $t('open') }}
                </RouterLink>
              </BTableColumn>
            </template>

            <template #detail="{ row: image }">
              <ImageDetails
                :image="image"
                :excluded-properties="excludedProperties"
              />
            </template>

            <template #empty>
              <div class="content has-text-grey text-center">
                <p>{{ $t('no-image') }}</p>
              </div>
            </template>
          </CytomineTable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce.js';
import {
  ImageInstanceCollection,
  ProjectCollection,
  TagCollection,
} from 'cytomine-client';
import { getWildcardRegexp } from '@/utils/string-utils.js';
import ImageName from '@/components/image/ImageName.vue';
import CytomineTable from '@/components/utils/CytomineTable.vue';
import ProjectDetails from '@/components/project/ProjectDetails.vue';
import ImageDetails from '@/components/image/ImageDetails.vue';
import CytomineMultiselect from '@/components/form/CytomineMultiselect.vue';
import IconProjectMemberRole from '@/components/icons/IconProjectMemberRole.vue';

export default {
  name: 'AdvancedSearch',
  components: {
    IconProjectMemberRole,
    ImageName,
    CytomineTable,
    ProjectDetails,
    ImageDetails,
    CytomineMultiselect,
  },
  data() {
    return {
      loading: true,
      error: false,

      searchString: '',
      projects: [],
      images: [],
      activeTab: 'projects',
      perPage: 10,

      selectedTags: [],
      availableTags: [],

      currentPage: 1,
      sortField: 'created',
      sortOrder: 'desc',
      openedDetails: [],
      revision: 0,

      excludedProperties: ['name', 'imagesPreview', 'lastActivity'],
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser.user;
    },

    pathSearchString() {
      return this.$route.params.searchString;
    },
    regexp() {
      return getWildcardRegexp(this.searchString);
    },
    projectCollection() {
      const collection = new ProjectCollection({
        withMembersCount: true,
        withLastActivity: true,
        withCurrentUserRoles: true,
      });
      if (this.searchString) {
        collection['name'] = {
          ilike: encodeURIComponent(this.searchString),
        };
      }

      if (
        this.selectedTags.length > 0 &&
        this.selectedTags.length < this.availableTags.length
      ) {
        collection['tag'] = {
          in: this.selectedTags.map((t) => t.id).join(),
        };
      }
      return collection;
    },
    imageCollection() {
      const collection = new ImageInstanceCollection({
        filterKey: 'user',
        filterValue: this.currentUser.id,
      });

      if (this.searchString) {
        collection['name'] = {
          ilike: encodeURIComponent(this.searchString),
        };
      }
      if (
        this.selectedTags.length > 0 &&
        this.selectedTags.length < this.availableTags.length
      ) {
        collection['tag'] = {
          in: this.selectedTags.map((t) => t.id).join(),
        };
      }
      return collection;
    },
  },
  watch: {
    pathSearchString(val) {
      if (val) {
        this.searchString = val;
      }
    },
  },
  async created() {
    this.searchString = this.pathSearchString || '';
    try {
      this.availableTags = [
        {
          id: 'null',
          name: this.$t('no-tag'),
        },
        ...(await TagCollection.fetchAll()).array,
      ];
    } catch (error) {
      console.log(error);
      this.error = true;
    }

    this.loading = false;
  },
  methods: {
    debounceSearchString: debounce(async function (value) {
      this.searchString = value;
    }, 500),
  },
};
</script>
